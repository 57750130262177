<template>
    <BannerTemplate :bannerSource="roadtrip.imageSrc">
        <div class="px-2 mt-n5 roadtrip-settings">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex">
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag ma-0 pa-0 text-center red" 
                                :class="`${roadtrip.tagColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{roadtrip.tripType}}</v-alert>
                        </span>
                        <div class="display-1">{{roadtrip.name}}
                            <img class="icon edit-icon" :src="editIcon" @click="goToRoadtripWijzigen(roadtrip.roadtripId)" />
                        </div>
                        <div class="ml-10 mt-n3">
                            <v-switch class="mt-3" color="green"
                            v-model="roadtrip.isPublished"
                            readonly
                            inset></v-switch>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-row no-gutters>
                    <v-list class="pa-0">
                        <v-list-item class="py-0 px-1 mt-n5">
                            <v-row justify="start">
                                <div>
                                    <span class="mx-2 body-1 font-weight-light text-lowercase">{{formatDayMonth(roadtrip.dateOnly)}}</span>
                                </div>

                                <div class="my-auto mr-2 icon-wrap">
                                    <img class="custom-icon" :src="carIcon" />
                                    <span class="subheading mr-2">{{roadtrip.registerCount}} / {{roadtrip.maxRegistrations}}</span>
                                </div>

                                <div class="my-auto mr-2 icon-wrap">
                                    <img class="custom-icon" :src="participantIcon" />
                                    <span class="subheading mr-2">{{roadtrip.peopleCount}}</span>
                                </div>
                                
                                <div class="my-auto mr-2 icon-wrap">
                                    <img class="custom-icon" :src="lunchIcon" />
                                    <span class="subheading mr-2">{{roadtrip.lunchCount}}</span>
                                </div>
                                
                                <div class="my-auto mr-2 icon-wrap"><img class="custom-icon" :src="hotelIcon" />
                                    <span class="subheading mr-2">{{roadtrip.hotelCount}}</span>
                                </div>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <p class="header-title mt-0 mb-0">Allergieën en voedselvoorkeuren</p>
                        <div class="mb-2">{{ allergyInfo() }}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="checklist">
                    <v-col>
                        <p class="header-title mt-0 mb-0">Checklist</p>
                        <div class="mb-2">
                             <v-list class="pa-0">
                                <v-list-item v-for="(item, index) in checklists" :key="index" 
                                class="pa-0"  :class="{ 'undone' : !item.isDone }">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action class="mr-3">
                                        <v-checkbox
                                            class="mr-2"
                                            v-model="item.isDone"
                                            :input-value="active"
                                            :label="`${item.date}`"
                                            color="primary"
                                        
                                        ></v-checkbox>
                                        </v-list-item-action>
                                     
                                        <v-list-item-content @click="item.isDone = !item.isDone" class="pa-0">
                                            {{item.task}}
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-5">
                    <v-col>
                        <p class="header-title mt-0 mb-0">Aanmeldingen</p>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-0 px-0 sort-container">
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'voornaam'">
                            Voornaam
                            <v-icon large right dark class="pr-2">{{currentSort == 'voornaam' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                        <v-btn plain
                            class="mr-4 pa-0 black white--text text-capitalize text-start"
                            @click="currentSort = 'merknaam'">
                            Merknaam
                            <v-icon large right dark class="pr-2">{{currentSort == 'merknaam' ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(participant, index) in sortedArray" :key="index">
                        <v-card class="participant-card primary">
                            <v-card-title
                            class="font-weight-bold cols-12 pt-2 pb-0">
                                {{ getCardText(participant) }}
                            </v-card-title>
                            <v-list class="pa-0 primary">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-thin">{{ getCardSubText(participant) }}</v-list-item-title>
                                    </v-list-item-content>
                                
                                    <v-list-item-action v-if="participant.hotelCount > 0">
                                        <div>
                                            <img class="hotel-icon" :src="hotelIcon" alt="Hotel" />
                                        </div>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="px-2">
            <v-container class="py-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <p class="display-1 font-weight-thin mb-2">Financiën</p>
                    </v-col>
                </v-row> 
               <StatisticInfoCards 
                    :title1="`Omzet:`"
                    :value1="`€2.387,50`"
                    :value1class="`title`"
                    :title2="`Kosten:`"
                    :value2="`€1.450,50`"
                    :value2class="`title`"
                    :title3="`Winst:`"
                    :value3="`€937,50`"
                    :value3class="`title`"
                    />
                <v-row>
                    <v-col>
                        <div class="pb-3">{{intro}}</div>
                        <v-btn color="primary" block @click="goToRoadtripNewInvoice(roadtrip.name, roadtrip.dateOnly)">
                            Nieuwe factuur toevoegen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <InvoiceList class="pb-5" />
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RouteMixin from "@/mixins/route.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import StatisticInfoCards from "@/components/admin/StatisticInfoCards";
import InvoiceList from "@/components/admin/InvoiceList";

export default {
    name: 'RoadtripBestaand',
    components: 
    {
        InvoiceList,
        StatisticInfoCards,
        BannerTemplate,
    },
    mixins: [DataMixin, FormatMixin, RouteMixin, RoadtripMixin],
    data: () => ({
        bannerSrc: require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
        editIcon: require('@/assets/icons/edit.svg'),
        carIcon: require('@/assets/icons/car-v2.svg'),
        participantIcon: require('@/assets/icons/participant.svg'),
        lunchIcon: require('@/assets/icons/lunch.svg'),
        hotelIcon: require('@/assets/icons/hotel.svg'),
        currentSort: 'voornaam',
        roadtripId: '',
        isLoading: false,
        roadtrip: {},
        allergies: [
                { name: "Glutenvrij",
                  count: 4 
                },
                { name: "Lactoseintolerant",
                  count: 2
                },
                { name: "Eieren",
                  count: 1
                }
            ],

            checklists: [
                { date: "2018-09-01", isDone: false, task: "Doe de laatste taak" },
                { date: "2018-08-25", isDone: true, task: "Doe een hele lange taak die zo lang is dat het niet normaal meer is." },
                { date: "2018-08-25", isDone: true, task: "Doe de normaale taak" },
                { date: "2018-08-23", isDone: true, task: "Doe de eerste taak" },
            ],

            participants: [
                {
                    name: "Ewoud Vlieg",
                    car: "Porsche Panamera Sport",
                    amountPaid: "€150,00",
                    passengerCount: 2,
                    lunchCount: 0,
                    dinnerCount: 0,
                    hotelCount: 1
                },
                {
                    name: "Ronald van Hameren",
                    car: "Porsche Boxster S 3.2",
                    amountPaid: "€50,00",
                    passengerCount: 0,
                    lunchCount: 0,
                    dinnerCount: 0,
                    hotelCount: 0

                },
                {
                    name: "Jedalisa Sevilla",
                    car: "Toyota Yaris",
                    amountPaid: "€999,00",
                    passengerCount: 3,
                    lunchCount: 4,
                    dinnerCount: 0,
                    hotelCount: 2

                },
            ]
    }),
    computed: 
    {
        sortedArray() 
        {
            let sortedParticipants = this.participants;
		
            sortedParticipants = sortedParticipants.sort((a,b) => {
                let text1 = "",
                    text2 = "";

                switch(this.currentSort)
                {
                    case "voornaam":
                        text1 = a.name.toLowerCase();
                        text2 = b.name.toLowerCase();
                        break;
                    case "merknaam":
                        text1 = a.car.toLowerCase();
                        text2 = b.car.toLowerCase();
                        break;
                }

                if (text1 < text2) {
                    return -1
                }
                if (text1 > text2) {
                    return 1
                }
                return 0
            })
            return sortedParticipants;
        },
        intro() {
            return `Hierboven zijn de financiële statistieken van de ${this.roadtrip.name} te zien. Via onderstaande knop een factuur worden toegevoegd waarvan de kosten bij de ${this.roadtrip.name} horen.`
        }
    },
    mounted () 
    {
        this.roadtripId = this.$route.params.id;
        // get roadtrip 
        this.getRoadtripInformation();
    },
    methods: 
    {
        getRoadtripInformation()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getRoadtripStatistics', this.roadtripId)
            .then(data => {
                this.roadtrip = data;
                this.roadtrip.dateOnly = this.getDateOnly(data.date);
                this.roadtrip.isPublished = data.productStatus == 2;
                this.roadtrip.tagColor = this.getTagColor(data.tripType);
                this.roadtrip.imageSrc = data.imageURL == null ? this.bannerSrc : data.imageURL;
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        allergyInfo()
        {
            let desc = "";
            this.allergies.forEach(function(i, index, array){
                desc = desc + `${i.name}: ${i.count}`;
                if (index < array.length - 1)
                {
                    desc = desc + "  |  "
                }
                
            });
            return desc;
        },
        getCardText(item)
        {
           return `${item.name} | ${item.car}`;
        },
        getCardSubText(item)
        {
           return `${item.amountPaid} | ${item.passengerCount} passagiers | ${item.lunchCount} lunchpakketten | | ${item.dinnerCount} diner`;
        }
    }
}
</script>
<style>

.roadtrip-settings 
{
    position: relative;
}

.roadtrip-settings .tag-wrap 
{
    position: absolute;
    right: 15px;
    top: -50px;
}

.custom-icon
{
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

.roadtrip-settings.theme--dark.v-card,
.roadtrip-settings .v-list.v-sheet.theme--dark
{
    background-color: #181717;
}

.tag-wrap .red
{
    background-color: red;
}

.v-list-item__action .v-label
{
    padding-left: 10px;
}

.v-list-item__content
{
    cursor: pointer;
}

.checklist .undone label,
.checklist .undone .v-list-item__content
{
    color: red;
    font-weight: bold;
}

.sort-container button.v-btn:not(.v-btn--round).v-size--default
{
    border-bottom: 1px solid #858282 !important;
    border-radius: 0px;
}

.theme--dark.v-card.participant-card,
.participant-card .theme--dark.v-list
{
    background-color: #005E39;
}

.participant-card .v-card__title
{
    margin-bottom: -10px;
}

.v-sheet.v-card.participant-card 
{
    border-radius: 10px;
}

.participant-card img.hotel-icon
{
    width: 20px;
}

.edit-icon {
   width: 20px; 
   height: 20px;
   cursor: pointer;
}
</style>